export class JudgementsModel
{
    ID:number;
    FormatedJdate: string;
    CaseID: number;
    Citation: string;
    Appeallant: string;
    Respondent: string;
    JDate: string;
    Court: string;
    CasePracticeArea: string;
    CaseTaggedStatutes: string;
    PublicDisplayValidity: string;
    CaseSummary: string;
    Active: number;
    IsDeleted: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: String;
    Title: string;
    Link: string;

    /* Case Detail */
    JudgeName:string;
    Judgment:string;
    Appeal:string;
    Keywords:string;
    Result:string;
    




}

