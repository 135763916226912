import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JudgementsModel } from '../models/judgementsModel';
import { Console } from '@angular/core/src/console';

@Injectable({
  providedIn: 'root'
})
export class JudgementsService {
  readonly baseUri ="http://eastlawapi.widerangedigital.com/api/";
  constructor(private http: HttpClient) { }
  objJudgementModel: JudgementsModel = new JudgementsModel();
  objJudgementModelList:JudgementsModel[];

  getAllJudgements(){
   return this.http.get(this.baseUri + "case/GetLatestJudgements").map( res => this.objJudgementModelList = res as JudgementsModel[]);
  }

  //http://localhost:33363/api/case/GetCases?ID=391174
  getJudgmentByID(id:number){
    //console.log("ID in Service : ",id);
    return this.http.get(this.baseUri + "case/GetCases?ID="+id).map( res => this.objJudgementModel = res as JudgementsModel);
    
  }


  getJudgementDetail (obj:JudgementsModel)
  {
    this.objJudgementModel=obj;

    // console.log("ID : ",this.objJudgementModel.ID);
    // console.log("FormatedJdate : ",this.objJudgementModel.FormatedJdate);
    // console.log("CaseID : ",this.objJudgementModel.CaseID);
    // console.log("Citation : ",this.objJudgementModel.Citation);
    // console.log("Appeallant : ",this.objJudgementModel.Appeallant);
    // console.log("Respondent : ",this.objJudgementModel.Respondent);
    // console.log("JDate : ",this.objJudgementModel.JDate);
    // console.log("Court : ",this.objJudgementModel.Court);
    // console.log("CasePracticeArea : ",this.objJudgementModel.CasePracticeArea);
    // console.log("CaseTaggedStatutes : ",this.objJudgementModel.CaseTaggedStatutes);
    // console.log("PublicDisplayValidity : ",this.objJudgementModel.PublicDisplayValidity);
    // console.log("CaseSummary : ",this.objJudgementModel.CaseSummary);
    // console.log("Title : ",this.objJudgementModel.Title);

  }

}
