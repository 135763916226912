import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DepartmentsNotificationsModel } from '../models/DepartmentsNotificationsModel.model';
import { DepartmentsSearchedResultsModel } from '../models/DepartmentsSearchedResultsModel.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  readonly baseUri = "http://eastlawapi.widerangedigital.com/api";
  constructor(private http: HttpClient) { }
  objNotifications: DepartmentsNotificationsModel[];
  objDepartmentsSearchedResults: DepartmentsSearchedResultsModel[];
  GetAllNotifications() {
    return this.http.get(this.baseUri + "/department/GetDepartmentFilesByGroupAndDeptParent?ParentDepID=0&depGroup=Notification");
  }
  GetAllCirculars() {
    return this.http.get(this.baseUri + "/department/GetDepartmentFilesByGroupAndDeptParent?ParentDepID=0&depGroup=Circular");
  }
  GetAllOthers() {
    return this.http.get(this.baseUri + "/department/GetDepartmentFilesByGroupAndDeptParent?ParentDepID=0&depGroup=Other");
  }
  GetAllDepartmentsList() {
    return this.http.get(this.baseUri + "/Department/GetDepartmentFilesTypesGroup?ID=0");
  }
  GetAllyearsList() {
    return this.http.get(this.baseUri + "/Department/GetYears/?ID=0");
  }
  GetDepartmentSearchResults(department: string, year: string, no: string) {
    return this.http.get(this.baseUri + "/Department/TypeSearch?departTypeGroupID=" + department + "&departTypeGroupName=" + department + "&typeNo=" + no + "&year=" + year + "");
  }
}
