import { Injectable } from '@angular/core';
import { SearchByRecentSearchModel } from '../models/SearchByRecentSearchModel.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetResultByIDService {
  readonly baseUri = "http://eastlawapi.widerangedigital.com/Api";
  objResult: SearchByRecentSearchModel;

  constructor(private http: HttpClient) { }

  getResult(ID: number){
    return this.http.get(this.baseUri + "/case/GetCases?ID=" + ID);
  }

}
